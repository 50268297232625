import './blocked.scss'
import Banner from "../../components/Banner/Banner";
import AdSense from 'react-adsense'

export default function Blocked() {
  return (
    <div className='blocked-page'>
      <Banner label='Zostałeś zablokowany!' />
      <section>
        <h1>
          Co to oznacza?
        </h1>
        <span>
          Nie możesz wysłać/poprawić swojej aplikacji na whitelist.
        </span>
        <h1>
          Dlaczego?
        </h1>
        <span>
          Prawdopodobną przyczyną blokady jest bezmyśla próba ominięcia ilości znaków w historii postaci. <br />
          Przykład: Dopisywanie niepotrzebnych znaków specjalnych, interpunkcyjnych i/lub liter.
        </span>
        <h1>
          Co teraz?
        </h1>
        <span>
          Napisz ticket na naszym serwerze <a target="_blank" href="https://discord.com/invite/humanityrp">Discord</a><br />
          Nie równa się to odblokowaniem dostępu!
        </span>
      </section>
      <AdSense.Google
        client='9296087987995778'
        slot='2571663002'
        style={{ width: '100%', height: 300, float: 'left' }}
        format=''
      />
    </div>
  )
}