import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './NavButton.css'

function NavButton({ children, label, to, onClick }) {
    const [animStart, setAnim] = useState(false)

    const handleClick = onClick ? onClick : ''

    return (
        <NavLink
            to={to}
            onClick={handleClick}
            className='navbutton'
            onMouseEnter={() => setAnim(true)}
            onMouseLeave={() => setAnim(false)}
        >
            <span className={`underline-hover-animation ${animStart && 'underline-hover-animation-done'}`}>
                {children}
            </span>
        </NavLink>
    )
}

export default NavButton