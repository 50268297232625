import './UserBar.scss'
import { Avatar, Button, Collapse, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import { getUser } from '../../app/slice/userSlice'
import { useState } from 'react'
import axios from '../../app/axios.js'
import { useNavigate } from 'react-router-dom'

export default function UserBar() {
  const user = useSelector(getUser)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const logout = () => {
    axios.get('auth/logout')
      .then(() => {
        navigate('/')
      })
  }

  return (
    <>
      <div className='user-bar-container'>
        <Avatar src={user.avatar} />
        <Button variant='contained'
          className='Button-contained'
          onClick={() => setOpen(!open)}
          size='large'>
          Informacje
        </Button>
        <Button variant='contained' size='large'
          onClick={() => logout()}
          className='Button-contained'>
          Wyloguj
        </Button>
      </div>
      <Collapse in={open}>
        <div className='user-bar-info'>
          <TextField label="Discord"
            className='TextField-root FilledInput-underline'
            value={user.discord_tag}
            variant="filled"
            sx={{ width: '100%' }}
            disabled />
          <TextField label="Steam"
            className='TextField-root FilledInput-underline'
            value={user.steam_name}
            variant="filled"
            sx={{ width: '100%' }}
            disabled />
          <TextField label="Steamid64"
            className='TextField-root FilledInput-underline'
            value={user.steamid64}
            variant="filled"
            sx={{ width: '100%' }}
            disabled />

          <Button variant='contained'
            className='Button-contained'
            href={`http://steamcommunity.com/profiles/${user.steamid64}`}
            size='large'>
            Profil steam
          </Button>
        </div>
      </Collapse>
    </>
  )
}