import './AuthError.scss'
import Banner from "../../components/Banner/Banner"
import AdSense from 'react-adsense'

export default function AuthError() {
  return (
    <div className='auth-error-page'>
      <Banner label='Błąd autoryzacji' />
      <section>
        <h1>
          Dołącz na Discord HumanityRP!
        </h1>
        <span>
          Aby złożyć aplikację musisz dołączyć na nasz serwer <a target="_blank" href="https://discord.gg/humanityrp">Discord</a>.
        </span>
        <h1>
          Połącz konto Discord z kontem Steam!
        </h1>
        <span>
        Musisz połączyć konto Discord z kontem Steam które będzie używane do rozgrywki na naszym serwerze.<br/>
          Ustawienia > Połączone konta
        </span>
        <h1>
          Nie znaleziono użytkownika
        </h1>
        <span>
          Występuje gdy nie udało się stworzyć lub znaleźć w bazie danych informacji o profilu użytkownika.
        </span>
        <AdSense.Google
          client='9296087987995778'
          slot='2571663002'
          style={{ width: '100%', height: 300, float: 'left' }}
          format=''
        />
      </section>
    </div>
  )
}