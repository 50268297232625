import { useState, useEffect } from 'react'
import './header.scss'
import hrplogo from '../../images/logo192.png'
import HamburgerMenu from '../Buttons/HamburgerMenu'
import NavButton from '../Buttons/NavButton'
import Sidebar from '../Sidebar/Sidebar'
import { useNavigate } from 'react-router-dom'

export const Header = () => {
  const navigate = useNavigate()
  const [openSidebar, toggleSidebar] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  }, [])

  const isSticky = (e) => {
    const header = document.querySelector('.header-container')
    const scrollTop = window.scrollY
    scrollTop >= 200 ? header.classList.add('full-color') : header.classList.remove('full-color')
  }

  return (
    <>
      <header className='header-container'>
        {/* <div className={style.fakeHeader} /> */}
        <div className='header'>
          <div className='headerLogo'>
            <a to='/'>
              <img src={hrplogo} alt='humanityrplogo' onClick={() => navigate('/')} />
            </a>
            <HamburgerMenu open={openSidebar} toggleSidebar={() => toggleSidebar(!openSidebar)} />
          </div>
          <div className='headerNavigation'>
            <NavButton to='/application'>
              Aplikacja whitelist
            </NavButton>
            <NavButton
                to='#discord'
                onClick={() => window.open('https://discord.com/invite/humanityrp', "_blank")}>
                Discord
            </NavButton>
            <NavButton to='/rules'>
              Regulamin
            </NavButton>
            <NavButton to='/lore'>
              Lore
            </NavButton>
            <NavButton to='/faq'>
              Najczęstsze pytania
            </NavButton>
            <NavButton to='/donate'>
              Wsparcie
            </NavButton>
          </div>
        </div>
      </header>
      <Sidebar open={openSidebar} />
    </>
  )
}