import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: true,
  applications: {}
}

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setApplications: (state, action) => {
      state.applications = action.payload
    },
    setApplicationsIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    clearApplications: (state, action) => {
      state.applications = {}
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setApplicationsIsLoading,
  setApplications,
  clearApplications
} = applicationsSlice.actions

export const applicationsIsLoading = state => state.applications.isLoading
export const getApplications = state => state.applications.applications

export default applicationsSlice.reducer