import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  Link,
  Route,
  Routes
} from 'react-router-dom'
import Dashboard from '../../views/Dashboard/Dashboard';
import NavLink from '../NavLink/NavLink';
import Applications from '../../views/Applications/Applications';
import Application from '../../views/Applications/Application';
import { Block, ExpandLess, ExpandMore, Folder, Home, Person, TextSnippet } from '@mui/icons-material';
import { CircularProgress, Collapse, ListSubheader } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getMoreAdminLogs, setMoreAdminLogsInfo, setMoreAdminLogsIsLoading } from '../../app/slice/moreAdminLogsSlice';
import axios from '../../app/axios';
import MoreAdminLogs from '../../views/MoreAdminLogs/MoreAdminLogs';
import './persistentDrawerLeft.scss'

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const moreAdminlogs = useSelector(getMoreAdminLogs)
  const [open, setOpen] = React.useState(true)
  const [openMoreAdminLogs, setOpenMoreAdminLogs] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const fetchMoreAdminLogs = () => {
    dispatch(setMoreAdminLogsIsLoading(true))
    axios.get('/logs/moreadminlogs/get/files')
      .then(res => {
        dispatch(setMoreAdminLogsIsLoading(true))
        dispatch(setMoreAdminLogsInfo(res.data.logs))
      })
      .catch(err => {
        dispatch(setMoreAdminLogsIsLoading(true))
        console.error(err)
      })
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            HumanityRP
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListSubheader component="div" id="nested-list-subheader">
            Ogólne
          </ListSubheader>
          <ListItem key='Dashboard' disablePadding
            component={NavLink} to='/dashboard'>
            <ListItemButton>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary='Dashboard' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Aplikacje' disablePadding
            component={NavLink} to='/dashboard/applications'>
            <ListItemButton>
              <ListItemIcon>
                <Folder />
              </ListItemIcon>
              <ListItemText primary='Aplikacje' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Profile' disablePadding
            component={NavLink} to='/dashboard/users'>
            <ListItemButton>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary='Profile' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Blokady' disablePadding
            component={NavLink} to='/dashboard/blockedusers'>
            <ListItemButton>
              <ListItemIcon>
                <Block />
              </ListItemIcon>
              <ListItemText primary='Blokady' />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <ListSubheader component="div" id="nested-list-subheader">
          Logi
        </ListSubheader>
        <List>
          <ListItemButton onClick={() => {
            setOpenMoreAdminLogs(!openMoreAdminLogs)
            fetchMoreAdminLogs()
          }}>
            <ListItemIcon>
              <TextSnippet />
            </ListItemIcon>
            <ListItemText primary='MoreAdminLogs' />
            {openMoreAdminLogs ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openMoreAdminLogs}>
            {
              moreAdminlogs.isLoading ? <CircularProgress />
                : moreAdminlogs.info.map(log => (
                    <ListItemButton key={log.folder} component={Link} to={`/dashboard/logs/moreadminlogs/${log.folder}`} sx={{ pl: 4 }}>
                      <ListItemText primary={log.date} />
                    </ListItemButton>
                  ))
            }
          </Collapse>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/application/:steamid" element={<Application />} />
          <Route exact path="/applications" element={<Applications />} />
          <Route exact path="/logs/moreadminlogs/:folder" element={<MoreAdminLogs />} />
        </Routes>
      </Main>
    </Box>
  );
}