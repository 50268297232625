import './rules.scss'
import Banner from '../../components/Banner/Banner'
import AdSense from 'react-adsense'

export const Rules = () => {
  return (
    <div className='rules-page'>
      <Banner label='Regulamin' />
      <div className='rules'>
        <h1>§1. Wstęp</h1>
        <span>
          Zanim przejdziesz do czytania dalszej części regulaminu pamiętaj, że jest on tylko szablonem rozgrywki RP. Jest ona na tyle dynamiczna, że czasem następują odstępstwa od niego, ponieważ każda sytuacja w grze różni się od siebie choćby najmniejszym czynnikiem lub szczegółem.
        </span>
        <h1>§2. Postanowienia ogólne</h1>
        <span>
          2.1 Gracz jest zobowiązany stosować się do podstawowych zasad trybu RolePlay.<br/>
          2.2 W przypadku naruszenia zasad opisanych w regulaminie użytkownik może tymczasowo lub permanentnie stracić prawa dostępu do serwera. Administracja jednocześnie zastrzega sobie prawo zablokowania użytkownika bez podania powodu ani przyczyny.<br/>
          2.3 Brak znajomości regulaminu nie zwalnia z jego przestrzegania.<br/>
          2.4 Stan upojenia alkoholowego nie zwalnia z przestrzegania poniższego regulaminu.<br/>
          2.5 Decyzja administracji jest ostateczna, możesz jednak odwołać się po połowie nadanej kary  poprzez odpowiedni kanał kontaktu z administracją (Ticket).<br/>
          2.6 Szacunek to podstawa. Należy zwracać się z odpowiednim szacunkiem do członka społeczności.<br/>
          2.7 Zabrania się korzystania z jakichkolwiek skryptów, cheatów, exploitów, bugów dających przewagę w grze.<br/>
          2.8 Każdy, kto widzi osobę łamiącą/nadużywającą regulamin ma obowiązek zgłoszenia tego Administracji.<br/>
          2.9 Zakazuje się przesiadywania na komunikatorach głosowych, komunikowania się poza grą oraz oglądania streamów innych graczy w czasie gry na serwerze, nawet “ w tle “.<br/>
          2.10 O zwrot utraconych rzeczy spowodowanych przez crashe, błędy należy zgłaszać w tickecie na discord razem ze zdjęciem/nagraniem oraz opisem jak do tego doszło (24h na zgłoszenie).<br/>
          2.11  Jeżeli jakaś baza wielkościowo/obiektowo będzie powodowała problemy w postaci crashy wtedy właściciel bazy będzie proszony o zmniejszenie jej lub usunięcie paru obiektów.<br/>
        </span>
        <h1>§3. Zasady Ogólne</h1>
        <span>
          3.1 Zabijanie innych graczy jest ostatecznością.<br/>
          3.2 Pamiętaj, że życie masz tylko jedno - szanuj je.<br/>
          3.3 Wczuj się w swoją postać tak jak to tylko możliwe i odgrywaj jej emocje.<br/>
          3.4 Pamiętaj, że nawet jeśli ktoś łamie regulamin Ty nigdy nie wychodź z roli. Ewentualne nieprawidłowości wraz z nagraniem zgłaszaj w tickecie bądź na in-game po zakończonej akcji RP.<br/>
          3.5 Obowiązuje kategoryczny zakaz używania faszystowskich i rasistowskich zwrotów (N-wordy, p-wordy, c-wordów).<br/>
          3.6 Obowiązuje kategoryczny zakaz napastowania, odgrywania gwałtów i napaści na tle seksualnym w kierunku innych ocalałych bez zgody OOC gracza, którego to dotyczy.<br/>
          3.7 W przypadku, gdy gracz rozłączy się z serwerem podczas prowadzenia akcji RP z innym graczem z powodów niezależnych od niego (brak internetu, crash itp.) jest zobowiązany zamieścić screen dokumentujący powód opuszczenia, datę oraz godzinę zajścia z paska zadań systemu Windows na kanale crashe na serwerze Discord. Screeny bez w/w elementów nie będą uznawane w przypadku skarg.<br/>
          3.8 Po rozłączeniu się z serwerem, a następnie po powrocie gracz jest zobowiązany w jak najszybszym czasie powrócić do wcześniejszej akcji RP.<br/>
        </span>
        <h1>§4. Zasady Discord/Sytuacji in-game</h1>
        <span>
          4.1 Gracz ma obowiązek stawienia się na Discordzie (poczekalnia In-game) gdy zostanie wezwany przez Administracje.<br/>
          4.2 Niezastosowanie się do (podpunktu 4.1) jest równoznaczne z nałożeniem tymczasowego bana do wyjaśnienia sytuacji.<br/>
          4.3 Podczas sytuacji In-game powinna być zachowana pełna kultura tj. brak wyzwisk, krzyków.<br/>
          4.4 Aby zgłosić gracza zalecane jest nagranie przedstawiające sytuację która się wydarzyła. Nagranie musi posiadać twój głos, innych osób oraz otoczenia.<br/>
          4.5 Kategoryczny zakaz nagrywania/streamowania sytuacji In-game.<br/>
          4.6 Każda sytuacja wyjaśniana na In-Game jest rozpatrywana indywidualnie.<br/>
        </span>
        <h1>§5. Rozgrywka</h1>
        <span>
          5.1 Każda osoba podejmująca agresywną akcję RP powinna posiadać nagranie całej sytuacji, ze swojej perspektywy (Gracz musi posiadać zapisane nagranie z akcji przynajmniej przez 24h). W innym przypadku podczas konfrontacji może zostać postawiony w mniej korzystnej sytuacji<br/>
          5.2 Zakaz KOS.<br/>
          5.3 Twój ton głosu musi być dostosowany do odgrywanej postaci. (Nie możesz odgrywać bandyty, mając młody głos, lecz można skorzystać w odpowiedni sposób z modulatora).<br/>
          5.4 Zakaz porywania drugiego gracza bez odpowiedniego podłoża fabularnego.<br/>
          5.5 Zakaz powrotu do swojego ciała w celu zdobycia swoich przedmiotów po statusie  “NIE ŻYJESZ”.<br/>
          5.6 Zabronione jest nadużywanie czatu OOC i stosowania na nim wulgaryzmów.<br/>
          5.7 Musisz posiadać sprawnie działający mikrofon, aby móc grać na serwerze. Musisz również z niego aktywnie korzystać.<br/>
          5.8 Zakaz używania zwrotów niemających odzwierciedlenia w życiu realnym i/lub które mogą zaburzać immersję innych graczy.<br/>
          5.9 Zakazuje się budowania baz na komisariacie, szpitalach, remizach, ośrodkach myśliwskich (nie dotyczy zamków).<br/>
          5.10 Zakaz budowania baz w obrębie 500 m od ośrodków militarnych.<br/>
          5.11 Po restarcie obowiązuje każdego 5-minutowy immunitet, dzięki któremu nie może dojść do nowej agresywnej akcji RP.<br/>
          5.12 Zakaz jakichkolwiek akcji agresywnych 10 minut przed restartem. (np. zakuwanie innego gracza).<br/>
          5.13 Zabronione jest używanie imion i nazwisk znanych postaci jak i postaci fikcyjnych (Celebrity Name).<br/>
          5.14 Zabronione jest używanie pojazdów do rozjeżdżania grup zombie oraz zwierząt. Nie dotyczy pojedynczych zombie na drodze oraz mniejszych zwierząt.<br/>
          5.15 Zabronione jest stawianie fizycznych obiektów na drodze w celu zablokowania drogi. Jednak gdy planujemy jakąś akcje RP która wymaga postawienia rzeczy na drodze należy skonsultować to z administracją.<br/>
          5.16 Zakaz kradzieży przedmiotów kosmetycznych donatora bez jego zgody.<br/>
          5.17 Gracza rozpoznać można tylko i wyłącznie po twarzy, wyglądzie postaci, ubiorze, opasce (również pojedyncze ubrania np. różowa czapka) które miał podczas napadu itp.<br/>
          5.18 Zakaz rozkładania większych obiektów (Namioty, szałasy, karawany, szopy) we wnętrzach budynków wyjątkiem są obszerne budynki jak magazyny etc.<br/>
          5.19 Zakaz rozkładania mebli, szafek itp. poza swoją bazą, lokum (nie tyczy się namiotów).<br/>
          5.20 Zakaz umyślnego przejeżdżania samochodem graczy.<br/>
          5.21 Zezwalamy na granie postaci chorej psychicznie, lecz nie zwalnia to Ciebie z przestrzegania regulaminu.<br/>
          5.22 Kiedy gracz tworzy nową postać, nie może dołączyć do tej samej zarejestrowanej grupy, w której był już wcześniej.<br/>
          5.23 Zakaz ubierania opasek innych grup bez wcześniejszego uzgodnienia tego z administracją.<br/>
          5.24 Zabudowywanie okien meblami do których można włożyć rzeczy jest na własną odpowiedzialność jeśli znikną jakieś rzeczy z tych mebli nie będą one podlegać zwrotowi.<br/>
          5.25 Rzeczy skradzione przez okna nie podlegają zwrotowi.<br/>
          5.26 Zakaz podawania jedzenia, picia, leków innej osobie bez jej zgody.<br/>
        </span>
        <h1>§6. Status postaci</h1>
        <span>
          6.1 Status “NIE ŻYJESZ” Zakończenie aktualnej sytuacji RP odgrywanej przez postać. Zapominasz o akcji, która do tego doprowadziła. Inna postać może "przypomnieć" o tej akcji o ile o niej wie lub uczestniczyła i przeżyła.<br/>
          6.2 Status “NIEPRZYTOMNY” po prostu nieprzytomność postaci. Po odzyskaniu przytomności, gracz może kontynuować wszystkie akcje RP. *<br/>
        </span>
        <h1>§7. Raidowanie baz</h1>
        <span>
          7.1 Raidować można tylko z dobrym podłożem IC.<br/>
          7.2 Podczas raid'u zakazane jest wyrzucanie przedmiotów na ziemię, palenia przedmiotów w ogniu oraz wychodzenia z serwera na terenie bazy innych graczy.<br/>
          7.3 Po wykonanym raidzie, kolejny można wykonać po upływie 7 dni.<br/>
          7.4 Zakaz wykorzystywania błędów w grze, wchodzenia na przedmioty (beczki itp), aby raidować bazę. Raidy należy przeprowadzać tak, aby ich dokonanie było zgodne z zasadami obowiązującymi w rzeczywistym świecie (np. jedna osoba nie napadnie bazy całej frakcji) oraz zasadami fizyki (np. lewitujące obiekty).<br/>
          7.5 Raid na baze może zostać przeprowadzony w godzinach od 17:00 do 01:00 włącznie. tj. Raid musi zakończyć się przed godziną 01:00.<br/>
          7.6 Przed każdym raidem należy poinformować administrację na odpowiednim kanale kontaktu (Ticket).<br/>
          7.7 Ginąc podczas obrony swojej bazy, nie możesz się w niej odrodzić ani wrócić do zakończenia akcji.<br/>
          7.8 Raid na bazę może być tylko przeprowadzony przez jedną grupę. Jakiekolwiek osoby z sojuszu nie mogą uczestniczyć podczas raidu jedynie mogą dostarczyć potrzebny sprzęt.<br/>
          7.9 Po zaakceptowaniu raidu należy go przeprowadzić w ciągu 48h inaczej pozwolenie wygasa.<br/>
          7.10 Strona broniąca jest informowana, że w ciągu 48h będzie przeprowadzony raid.<br/>
          7.11 Strona atakująca ma zakaz zakładania codelocków podczas trwania raidu oraz po raidzie.<br/>
          7.12 Strona broniąca się ma zakaz zakładania codelocków podczas trwania raidu.<br/>
        </span>
        <h1>§8. Agresywne akcje </h1>
        <span>
          8.1 Wymagamy od graczy dołożenia wszelkich starań, aby zaangażowali się oni w rozgrywkę w trybie Role-Play i unikali rozgrywki w stylu PVP lub samego grindu.<br/>
          8.2 Musisz mieć fabularny (9.11) powód Role-Play dla wszystkich decyzji i działań podejmowanych przez twoją postać.<br/>
          8.3 Akcje rabunkowe są zwolnione z przestrzegania punktu (8.2). Jednakże nadmierne wykorzystywanie, będzie karane.<br/>
          8.4 Zakaz szybkiego rabunku (9.13).<br/>
          8.5 Wszystkie wrogie działania i inicjacje, w tym wszelkie polecenia i żądania, muszą być jasne dla wszystkich zaangażowanych w danej akcji.<br/>
          8.6 Strona atakująca musi brać pod uwagę, że ich ofiara może się obronić.<br/>
          8.7 Gracze w poruszających się pojazdach nie muszą szanować swojego życia, gdy ktoś wykonuje inicjacje w ich kierunku. Jednak gracze, którzy próbują zainicjować w kierunku pojazdu, muszą przestrzegać normalnych zasad inicjacji. <br/>
          8.8 Agresorzy muszą dać ofiarom odpowiednią ilość czasu na odpowiedź na ich żądania przed wymierzeniem kary. Ofiara musi się niezwłocznie poddać (chyba że akcja daje przewagę ofierze - do czego konieczne jest nagranie).<br/>
          8.9 Nie możesz wymagać od ofiary otwarcia drzwi do bazy lub obozowiska.<br/>
          8.10 Podczas rabunku musisz pozostawić przedmioty do przeżycia:<br/>
          <ul>
            <li>
              Rozsądna ilość jedzenia i picia,
            </li>
            <li>
              Rozsądna ilość szmatek/bandaży,
            </li>
            <li>
              Broń biała,
            </li>
            <li>
              Buty.
            </li>
          </ul>
        </span>
        <h1>§9. Pojęcia</h1>
        <span>
          9.1 <b>In Character (IC)</b> - Czyli wszystko to co dotyczy twojej postaci a także całej gry i absolutnie nic poza nią.<br/>
          9.2 <b>Out of Character (OOC)</b> - Wszystko co dotyczy świata poza gry.<br/>
          9.3 <b>Power Gaming (PG)</b> - ymuszenie na kimś akcji RP uniemożliwiając mu reakcje.<br/>
          9.4 <b>Metagaming (MG)</b> - Wykorzystywanie informacji OOC w   świecie IC.<br/>
          9.5 <b>Fail RP</b> - Nieralistyczne, nielogiczne odegranie roli lub wyjście z niej.<br/>
          9.6 <b>Character Kill (CK)</b> - Całkowite uśmiercenie postaci za zgodą administracji.<br/>
          9.7 <b>Vehicle Deathmatch (VDM)</b> - Celowe przejeżdżanie graczy.<br/>
          9.8 <b>KOS</b> - Próba/zabójstwo gracza bez inicjacji.<br/>
          9.9 <b>Raid</b> - Włamywanie się do bazy innego gracza.<br/>
          9.10 <b>Baza</b> - Miejsce zabezpieczone w 100% codelockiem/szyfrem.<br/>
          9.11 <b>Fabuła</b> - Nie jest równa historii postaci. Jest dyktowana przez wydarzenia na serwerze.<br/>
          9.12 <b>Poszanowanie życia </b> - Musisz szanować życie postaci cały czas podczas rozgrywki na serwerzey.<br/>
          9.13 <b>Szybki Rabunek </b> - Obrabowywanie graczy tylko ze względu na ich sprzęt w miejscu bądź okolicy podjęcia inicjacji oraz bez podjęcia ciekawej interakcji z graczem.<br/>
        </span>
      </div>
      <AdSense.Google
        client='9296087987995778'
        slot='7806394673'
        style={{ width: '100%', height: 300, float: 'left' }}
        format=''
      />
    </div>
  )
}