import { useNavigate } from 'react-router-dom'
import Banner from '../../components/Banner/Banner'
import './aplication.scss'
import { Button, Divider, List } from '@mui/material'
import { Box } from '@mui/system'

export default function ApplicationStart() {
  const navigate = useNavigate()
  return (
    <div className='application-page'>
      <Banner label='Aplikacja whitelist' />
      <div className='application'>
        <h1>Jak złożyć aplikację?</h1>
        <span>
          Aby złożyć aplikację musisz:
        </span>
        <span>1. Być na naszym serwerze <a href='https://discord.com/invite/humanityrp' target='_blank'>Discord</a>,</span>
        <span>2. Musisz zapoznać się z <a href='#regulamin' onClick={() => navigate('/rules')}>regulaminem</a> oraz <a href='#lore' onClick={() => navigate('/lore')}>lore</a> serwera,</span>
        <span>3. Twoje konto Discord musi być połączone z kontem Steam. Pamiętaj aby było to konto które będzie używane do rozgrywki na serwerze.</span>
        <List sx={{ width: '100%' }}>
          <Divider />
        </List>
        <span>Jeśli podanie zostanie rozpatrzone status będziesz mógł sprawdzić na stronie i/lub dostaniesz wiadomość na Discord.</span>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%', padding: '1rem 0' }}>
          <Button variant="contained" size='large'
            sx={{ marginBottom: '3rem' }}
            className='Button-contained'
            href='https://discord.com/api/oauth2/authorize?client_id=1066756567027957891&redirect_uri=https%3A%2F%2Fwww.humanityrp.pl%3A9090%2Fauth%2Fdiscord%2Fcollback&response_type=code&scope=identify%20guilds%20connections' >
            Zaloguj przez Discord
          </Button>
        </Box>
      </div>
    </div>
  )
}