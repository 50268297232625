import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: true,
  application: {
    characterName: '',
    characterHistory: '',
    expiranceRP: '',
    question1: '',
    question2: '',
    question3: '',
    question4: ''
  }
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setApplication: (state, action) => {
      state.application[action.payload.element] = action.payload.text
    },
    clearApplication: (state, action) => {
      state.application = {
        characterName: '',
        characterHistory: '',
        expiranceRP: '',
        question1: '',
        question2: '',
        question3: '',
        question4: ''
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setApplication,
  clearApplication
} = applicationSlice.actions

export const getApplicationLoading = state => state.application.isLoading
export const getApplication = state => state.application.application

export default applicationSlice.reducer