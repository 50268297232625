import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slice/userSlice'
import applicationSlice from './slice/aplicationSlice'
import notificationSlice from './slice/notificationSlice'
import myApplicationSlice from './slice/myAplicationSlice'
import moreAdminLogsSlice from './slice/moreAdminLogsSlice'
import applicationsSlice from './slice/aplicationsSlice'
import applicationATSlice from './slice/applicationATSlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    notifications: notificationSlice,
    application: applicationSlice,
    myApplication: myApplicationSlice,
    applications: applicationsSlice,
    applicationat: applicationATSlice,
    moreAdminLogs: moreAdminLogsSlice
  },
})
