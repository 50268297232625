import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: true,
  application: {}
}

export const applicationATSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setApplication: (state, action) => {
      state.application = action.payload
    },
    clearApplication: (state, action) => {
      state.application = {}
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setApplicationIsLoading,
  setApplication,
  clearApplication
} = applicationATSlice.actions

export const getApplication = state => state.applicationat

export default applicationATSlice.reducer