import './lore.scss'
import Banner from '../../components/Banner/Banner'
import AdSense from 'react-adsense'
import flag from '../../images/Flag_-_Chernarus.webp'

export const Lore = () => {
  return (
    <div className='lore-page'>
      <Banner label='Lore' />
      <div className='lore'>
        <span>
          Czarnoruś to post-radziecka republika w regionie Morza Zielonego w pobliżu Kaukazu, granicząca z Federacją Rosyjską i Takistanem. Znaczna część północnej części kraju pokryta jest lasami – iglastymi oraz mieszanymi. Na południu Czarnorusi lasów jest zdecydowanie mniej. Wyróżniającym się miejscem na mapie Czarnorusi jest Wyspa Skalista, która w 1987 r. została ogłoszona rezerwatem przyrody w ramach nowo powstałego stowarzyszenia "Zagorie Protected Natural Area", dlatego też na tym obszarze znajduje się mało zabudowań i w zasadzie nietknięty ludzką ręką krajobraz.
        </span>
        <span>
          Ludność Czarnorusi to przede wszystkim większość rosyjska, zamieszkująca północno-wschodnią i południową Zagorię, z największym skupiskiem w Kransotavie. Stosunki między ludnością rosyjską a mniejszością czarnoruską są napięte, głównie ze względów politycznych. Czarnoruś do 1917 r. była częścią imperium rosyjskiego, lecz w wyniku rewolucji październikowej, stała się autonomiczną republiką federalną, która po upadku ZSRR w 1991 r. uzyskała niepodległość. Nie wszyscy mieszkańcy republiki zaakceptowali tę zmianę. Tęsknota za byciem częścią atomowego, socjalistycznego supermocarstwa doprowadziły w końcu do wybuchu wojny domowej w 2009 r.
        </span>
        <span>
          Komunistyczny Czarnoruski Ruch Czerwonej Gwiazdy (CRRR) dążył do utworzenia obalenia władz i wprowadzenia socjalizmu na terenie Czarnorusi. Terrorystyczne i wyjątkowo brutalne działania ugrupowania skutkowały dokonaniem licznych zbrodni wojennych na ludności czarnoruskiej. Próby zatuszowania ostatecznie nie powiodły się, głównie za sprawą zamachu bombowego, przeprowadzonego na moskiewskich Placu Czerwonym w czerwcu 2010 r. Rosyjski rząd, wspierający działania CRRR, bardzo szybko oskarżył Narodową Partię Czarnorusi o przeprowadzenie ataku, po czym wprowadził swoje wojska do południowej Zagorii. Rosjanie, motywując swoje działania potrzebą wprowadzenia porządku na Czarnorusi, przekazali zapasy broni dla Ruchu Czerwonej Gwiazdy, prowadzącej działanie zbrojne przeciwko legalnemu rządowi Czarnorusi, co z kolei doprowadziło do kolejnych zbrodni i uznania przez międzynarodową opinię publiczną, że wydarzenia w Czarnorusi noszą znamiona ludobójstwa.
        </span>
        <span>
          Pod koniec 2010 r. cały region południowej Zagorii pogrążony był w chaosie wojny domowej. Prezydent Czarnorusi, Aleksandr Baranow, wezwał do pomocy i zakończenia działań zbrojnych ONZ. Na miejsce wysłana została Marynarka USA oraz siły pokojowe. Ich zadaniami było zabezpieczyć nadmorskie miasta takie jak Czernogórsk, Elektrozawodsk czy Berezino oraz rozbrojenie terrorystów.
        </span>
        <span>
          Szeroko zakrojone działania militarne oraz skoordynowane ataki powietrzne amerykańskiego lotnictwa floty doprowadziły do wygaszenia konfliktu. W końcu wojska Federacji Rosyjskiej, jak i Marynarka Stanów Zjednoczonych wycofały się, a dalszą rolę nad działaniami stabilizacyjnymi przejęły oddziały ONZ. Kraj został podzielony na sektory znajdujące się pod opieką międzynarodowych kontyngentów wojskowych. To właśnie między innymi do południowej Zagorii wysłano polski kontyngent PKW Czarnoruś. Misja stabilizacyjna dała mieszkańcom Czarnorusi nadzieję, że te niespokojne lata powoli mijają i nadchodzi szansa na lepsze jutro.
        </span>
        <span>
          Niestety, w momencie, w którym w Czarnorusi milkły eksplozje, a życie wracało do normalności, z Zachodu docierały informacje o dziwnej i szybko rozprzestrzeniającej się chorobie. Objawiała się silną gorączką i majaczeniem - początkowo myślano, że jest to nowy, groźniejszy szczep grypy. Szybko jednak pojawiły się wątpliwości, gdy po 2 miesiącach od pierwszego przypadku zachorowania pierwsi zakażeni wirusem zaczęli zamieniać się w bezmyślne i żądne ludzkiej krwi bestie. Wielu twierdzi, że zarażeni wciąż żyją, lecz nie są świadomi tego, co robią. Na całym świecie podjęto
          natychmiastowe działania, aby uporać się z nadchodzącą apokalipsą, jednak w ciągu dwóch lat sytuacja stała się jeszcze bardziej dramatyczna.
        </span>
        <span>
          Po pojawieniu się zarażonych na Czarnorusi, ONZ próbowała powstrzymać falę zarazy, testując eksperymentalne metody leczenia, jednak wszelkie próby wynalezienia szczepionki nie powiodły się. Najlepsi naukowcy i lekarze z całego świata padali ofiarą śmiercionośnej zarazy.
        </span>
        <span>
          Skala pandemii była olbrzymia. Miasta, wsie oraz cała infrastruktura została porzucona. Nawet elektrownie atomowe opustoszały, co w konsekwencji doprowadziło do tragicznego wypadku. Niezakonserwowane rdzenie reaktorów w przygranicznej elektrowni przegrzały się. Doszło do potężnej eksplozji, która przyniosła straty dla połowy obszaru Czarnorusi, pogrążając go w radiacji, która uniemożliwiła poruszanie się i odcięła region jeszcze bardziej od świata. Zaobserwowano, że opad radioaktywny zaczął wchodzić w związek z wirusem, powodując jeszcze straszniejsze mutacje i tworząc z zarażonych maszyny do zabijania.
        </span>
        <span>
          Świat podzielił się nie do poznania. Przetrwały jedynie niewielkie grupy ocalałych, bez kontaktu ze sobą, oddalone o tysiące kilometrów. Wszystko pogrążyło się w bezgranicznym chaosie. Ziemia, będąca domem dla ludzkości, gdzie jeszcze niedawno wszyscy czuli się jego częścią dość szybko zapomnieli o tragicznych wydarzeniach sprzed kilku lat na Czarnorousi. Ci, którzy przetrwali, pozostawieni samym sobie, szukają przyjaźni i pokrzepienia serc, łączą się w niewielkie grupy i tworzą małe osady, próbując zachować człowieczeństwo.
        </span>
        <span>
          Czarnoruś to miejsce, które stało się azylem dla ocalałych z całego regionu, gdzie resztki ONZ próbują uporać się z zarazą i dać ocalałym resztki nadziei. Tylko ona przecież pozostała w tym nieludzkim świecie, gdzie „żywa śmierć” jest jedyną pewną rzeczą, jaka może Ci się przytrafić. Tutaj zaczyna się Twoja historia…
        </span>
      </div>
      <AdSense.Google
        client='9296087987995778'
        slot='2571663002'
        style={{ width: '100%', height: 300, float: 'left' }}
        format=''
      />
    </div>
  )
}