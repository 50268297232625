import Banner from '../../components/Banner/Banner'
import './home.scss'
import HomeWidget from './HomeWidget'
import mapImg from '../../images/map.png'
import survivalImg from '../../images/survival.jpg'
import tradeImg from '../../images/trade.jpg'
import AdSense from 'react-adsense'

export const Home = () => {

  return (
    <div className='home-page'>
      <Banner label='HumanityRP' />
      <div className='home-widgets'>
        <iframe style={{ alignSelf: 'center' }} width="100%" height="420" src="https://www.youtube.com/embed/FkzjPVAmkmY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <HomeWidget img={mapImg} label='Osady' side='left'>
          Chernaruś zamieszkuje wbrew pozorom wiele osób. Niektórzy, aby zwiększyć szansę na przeżycie lub po prostu nie zwariować w samotności łączą się w grupy. Ci wytrwalsi postanowili założyć swoje osady, w których kwietnie handel i pomoc potrzebującym. Niech to nie zmyli twojej czujności, nie każdy pali się do pomocy, wręcz przeciwnie...
        </HomeWidget>
        <HomeWidget img={tradeImg} label='Handel' side='right'>
          Handel to nieodłączny element cywilizacji. Barter, gotówka, wszystko wchodzi w grę, zależy kto czego potrzebuje. Handel najbardziej kwitnie w osadach, lecz jest spore prawdopodobieństwo, że przypadkowo spotkana osoba będzie chciała się wymienić, albo po prostu sobie to zabrać...
        </HomeWidget>
        <HomeWidget img={survivalImg} label='Przetrwanie' side='left'>
          Jest to nieodłączny element świata przedstawionego, a nawet głównym celem jeszcze żywych. Na drodze napotkasz wiele niebezpieczeństw takich jak zombie, dzika zwierzyna, inni ludzie. Niebezpieczeństwa na tym się nie kończą, zawsze może zabraknąć Ci pożywienia, zachorować na jedną z wielu chorób lub po prostu zamarznąć od niskiej temperatury...
        </HomeWidget>
        <AdSense.Google
          client='9296087987995778'
          slot='2571663002'
          style={{ width: '100%', height: 300, float: 'left' }}
          format=''
        />
      </div>
    </div>
  )
}