import { useEffect, useState } from 'react'
import Banner from '../../components/Banner/Banner'
import './aplication.scss'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../../axios'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearMyApplication,
  getMyApplication,
  loadMyApplication,
  myApplicationIsLoading,
  setMyApplication,
  setMyApplicationLoading }
from '../../app/slice/myAplicationSlice'
import { Button, CircularProgress, Divider, List, TextField } from '@mui/material'
import UserBar from '../../components/UserBar/UserBar'
import { useSnackbar } from 'notistack'
import { getUser } from '../../app/slice/userSlice'

export default function Application() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const params = useParams()
  const user = useSelector(getUser)
  const myApplication = useSelector(getMyApplication)
  const isLoading = useSelector(myApplicationIsLoading)
  const [historyCharacterCount, setHistoryCharacterCount] = useState(0)
  const [errors, setErrors] = useState({
    steamid64: "",
    discord: "",
    characterName: "",
    characterHistory: "",
    expiranceRP: "",
    question1: "",
    question2: "",
    question3: "",
    question4: "",
  })

  useEffect(() => {
    dispatch(setMyApplicationLoading(true))
    axios.get(`/application/get/${params.steamid}`)
      .then(res => {
        const data = res.data
        switch (data.code) {
          case 'APPLICATION_ACCESS_DENY':
            navigate(0)
            break;
          case 'DB_ERROR':
            enqueueSnackbar(res.data.msg, { variant: 'error' })
            break;
          case 'APPLICATION_CANNOT_FIND':
            enqueueSnackbar(res.data.msg, { variant: 'error' })
            navigate(0)
            break;
          case 'APPLICATION_GET_SUCCESS':
            dispatch(loadMyApplication(data.application))
            dispatch(setMyApplicationLoading(false))
            break;
          default:
            break;
        }
      })
      .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    if (myApplication.character_history) {
      setHistoryCharacterCount(myApplication.character_history.replace(/\s/g, '').length)
    }
  }, [myApplication.character_history])

  const resendApplication = () => {
    axios.post('/application/resend', {
      characterName: myApplication.character_name,
      characterHistory: myApplication.character_history,
      expiranceRP: myApplication.expirance_rp,
      question1: myApplication.questions.question1.anwser,
      question2: myApplication.questions.question2.anwser,
      question3: myApplication.questions.question3.anwser,
      question4: myApplication.questions.question4.anwser,
    })
    .then(res => {
      switch (res.data.code) {
        case 'COMPILE_SUCCESS':
          enqueueSnackbar(res.data.msg, { variant: 'success' })
          dispatch(clearMyApplication())
          setErrors({
            steamid64: "",
            discord: "",
            characterName: "",
            characterHistory: "",
            expiranceRP: "",
            question1: "",
            question2: "",
            question3: "",
            question4: "",
          })
          navigate(0)
          break;
        case 'COMPILE_ERROR':
          enqueueSnackbar(res.data.msg, { variant: 'error' })
          setErrors(res.data.errors)
          break;
        case 'DB_ERROR':
          enqueueSnackbar(res.data.msg, { variant: 'error' })
          break;
        case 'AUTH_ERROR':
          enqueueSnackbar(res.data.msg, { variant: 'error' })
          break;
        default:
          break;
      }
    })
    .catch(err => console.error(err))
  }

  return (
    <div className='application-page'>
      <Banner label='Moja aplikacja' />
      {
        isLoading ? (
          <CircularProgress sx={{ marginTop: '2rem' }} />
        ) : (
          <div className='application-container'>
            <UserBar />
            <h1>Moja aplikacja na whitelist</h1>
            <TextField label='Status' variant='filled'
              className='TextField-root FilledInput-underline'
              disabled
              value={myApplication.status.status}
              sx={{ width: '100%' }} />
            {
              myApplication.status.message && (
                <TextField label='Wiadomość od sprawdzającego' variant='filled'
                  className='TextField-root FilledInput-underline'
                  disabled
                  value={myApplication.status.message}
                  sx={{ width: '100%' }} />
              )
            }
            <List sx={{ width: '100%' }}>
              <Divider />
            </List>
            <TextField label="Imię i nazwisko" variant="filled"
              className='TextField-root FilledInput-underline'
              disabled={myApplication.status.status === 'Odrzucona' ? false : true}
              error={errors.characterName ? true : false}
              helperText={errors.characterName}
              value={myApplication.character_name}
              onChange={e => dispatch(setMyApplication({ element: 'character_name', text: e.target.value }))}
              sx={{ width: '100%' }} />
            <TextField label={`Historia postaci (${historyCharacterCount}/2000)`} variant="filled"
              className='TextField-root FilledInput-underline'
              disabled={myApplication.status.status === 'Odrzucona' ? false : true}
              error={errors.characterHistory ? true : false}
              helperText={errors.characterHistory}
              value={myApplication.character_history}
              onChange={e => dispatch(setMyApplication({ element: 'character_history', text: e.target.value }))}
              sx={{ width: '100%' }}
              multiline
              maxRows={10} />
            <TextField label="Twoje doświadczenie z RP" variant="filled"
              className='TextField-root FilledInput-underline'
              disabled={myApplication.status.status === 'Odrzucona' ? false : true}
              error={errors.expiranceRP || '' ? true : false}
              helperText={errors.expiranceRP || ''}
              value={myApplication.expirance_rp || ''}
              onChange={e => dispatch(setMyApplication({ element: 'expirance_rp', text: e.target.value }))}
              sx={{ width: '100%' }}
              multiline
              maxRows={4} />
            <div className='question'>
              Odłączyłeś/aś się od grupy, Twoja postać ZGINĘŁA. Odradzasz się, wracasz do swojego obozowiska/swoich znajomych. Zaczynają się Ciebie pytać co się stało. Jak reagujesz, co odpowiadasz? Opisz krótko sytuację oraz uzasadnij swoją odpowiedź.
            </div>
            <TextField label="Odpowiedź #1" variant="filled"
              className='TextField-root FilledInput-underline'
              disabled={myApplication.status.status === 'Odrzucona' ? false : true}
              error={errors.question1 ? true : false}
              helperText={errors.question1}
              value={myApplication.questions.question1.anwser}
              onChange={e => dispatch(setMyApplication({ element: 'question1', text: e.target.value }))}
              sx={{ width: '100%' }}
              multiline
              maxRows={4} />
            <div className='question'>
              Napada na Ciebie uzbrojona grupa, celują do Ciebie, z sytuacji wynika, że chcą byś oddał/a im swoje rzeczy. Opisz co robisz w tej sytuacji.
            </div>
            <TextField label="Odpowiedź #2" variant="filled"
              className='TextField-root FilledInput-underline'
              disabled={myApplication.status.status === 'Odrzucona' ? false : true}
              error={errors.question2 ? true : false}
              helperText={errors.question2}
              value={myApplication.questions.question2.anwser}
              onChange={e => dispatch(setMyApplication({ element: 'question2', text: e.target.value }))}
              sx={{ width: '100%' }}
              multiline
              maxRows={4} />
            <div className='question'>
              Napadasz na gracza. Opisz jak odegrałbyś taką akcję. W swojej wypowiedzi uwzględnij z jakich przedmiotów możesz ograbić innych, oraz uzasadnij swoją wypowiedź.
            </div>
            <TextField label="Odpowiedź #3" variant="filled"
              className='TextField-root FilledInput-underline'
              disabled={myApplication.status.status === 'Odrzucona' ? false : true}
              error={errors.question3 ? true : false}
              helperText={errors.question3}
              value={myApplication.questions.question3.anwser}
              onChange={e => dispatch(setMyApplication({ element: 'question3', text: e.target.value }))}
              sx={{ width: '100%' }}
              multiline
              maxRows={4} />
            <div className='question'>
              Wędrowałeś/aś ze swoim kompanem, rozdzieliła Was horda zombie, rozbiegliście się w dwóch różnych kierunkach i zgubiłeś drogę do obozowiska. Jak poinformujesz kolegę gdzie się znajdujesz? Jak znajdziesz drogę do domu? Opisz krótko sytuację.
            </div>
            <TextField label="Odpowiedź #4" variant="filled"
              className='TextField-root FilledInput-underline'
              disabled={myApplication.status.status === 'Odrzucona' ? false : true}
              error={errors.question4 ? true : false}
              helperText={errors.question4}
              value={myApplication.questions.question4.anwser}
              onChange={e => dispatch(setMyApplication({ element: 'question4', text: e.target.value }))}
              sx={{ width: '100%' }}
              multiline
              maxRows={4} />
            {
              myApplication.status.status === 'Odrzucona' && (
                <Button variant='contained'
                  className='Button-contained'
                  size='large'
                  onClick={() => resendApplication()}>
                  Aplikuj ponownie
                </Button>
              )
            }
          </div>
        )
      }
    </div>
  )
}