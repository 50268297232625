import './Footer.scss'
import NavButton from '../Buttons/NavButton'
import discordLogo from '../../images/discord-mark-white.svg'

function Footer() {
    return (
        <div className='footer-container'>
            <div className='footer'>
                <div className='fit-content flex justify-conent-sapce-between'>
                    <div className='link-section'>
                        <div className='footer-widget'>
                            <span className='footer-widget-title'>
                                Media
                            </span>
                            <NavButton
                                to='#ig'
                                onClick={() => window.open('https://www.instagram.com/humanityrp_official/', "_blank")}>
                                Instagram
                            </NavButton>
                            <NavButton
                                to='#yt'
                                onClick={() => window.open('https://www.youtube.com/@MahauuTV', "_blank")}>
                                YouTube
                            </NavButton>
                        </div>
                        <div className='footer-widget'>
                            <span className='footer-widget-title'>
                                Społeczność
                            </span>
                            <NavButton
                                to='#discord'
                                onClick={() => window.open('https://discord.com/invite/humanityrp', "_blank")}>
                                Discord
                            </NavButton>
                            <NavButton
                                to='/rules'>
                                Regulamin
                            </NavButton>
                        </div>
                        <div className='footer-widget'>
                            <span className='footer-widget-title'>
                                HumanityRP
                            </span>
                            <NavButton
                                to='/faq'>
                                Najczęstsze pytania
                            </NavButton>
                            <NavButton
                                to='/donate'>
                                Wsparcie
                            </NavButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className='coppyright-section'>
                <div className='fit-content flex justify-conent-sapce-between'>
                    <div className='coppyright-bi'>
                        Humanity is not affiliated with or authorized by Bohemia Interactive a.s. Bohemia Interactive, ARMA®, DayZ® all associated logos and designs are trademarks or registered trademarks of Bohemia Interactive a.s.
                    </div>
                    <div className='coppyright-rights'>
                        © 2023 Humanity. All rights reserved<br />
                        Design and implementation: <br />
                        <img className='discord-logo' src={discordLogo} alt='discord logo' /> MrKamil404#1520
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer