import { ListSubheader, Paper, Typography } from '@mui/material'
import styled from 'styled-components'

const InformactionWindowStyled = styled(Paper)`
  width: ${props => props.width || 'none'};

  @media (max-width: 768px) {
    width:  100% !important;
  }
`

export default function InformactionWindow({ key, children, label, width, labelLineHeight, contentMaxHeight }) {
  return (
    <InformactionWindowStyled width={width}>
        <ListSubheader sx={{ lineHeight: labelLineHeight || '3rem', padding: labelLineHeight ? '1rem 1rem' : '0 1rem'}}>
          {label}
        </ListSubheader>
        <Typography component='div' width='100%' padding='.5rem' sx={{ whiteSpace: 'pre-line', width: '100%', maxHeight: contentMaxHeight, overflowY: 'auto' }}>
          {children}
        </Typography>
    </InformactionWindowStyled>
  )
}