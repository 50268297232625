import { Button } from "@mui/material"
import { LoginPage } from "./styled/LoginStyled"

export default function Login() {
  return (
    <LoginPage>
      <Button
        size='large'
        variant='contained'
        href='https://discord.com/api/oauth2/authorize?client_id=1046361405735059507&redirect_uri=https%3A%2F%2Fwww.humanityrp.pl%3A9090%2Fauth%2Fdiscord%2Fcollback&response_type=code&scope=identify%20guilds%20connections'>
        Zaloguj
      </Button>
    </LoginPage>
  )
}