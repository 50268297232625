import { useState, useEffect } from 'react'
import { IconButton, TableRow, TableCell, Collapse } from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'
import InformactionWindow from '../../components/InformactionWindow/InformactionWindow'
import { Box } from '@mui/system'

export default function MoreAdminLog({ expandAll, log }) {
  const [expand, setExpand] = useState(false)

  useEffect(() => {
    expandAll ? setExpand(true) : setExpand(false)
  }, [expandAll])

  return (
    <>
      <TableRow>
        <TableCell>
          {log.time}
        </TableCell>
        <TableCell>
          {log.player.position.x + ', ' + log.player.position.y + ', ' + log.player.position.z}
        </TableCell>
        <TableCell>
          {log.player.steamId || 'Nieznany'}
        </TableCell>
        <TableCell>
          <IconButton onClick={() => setExpand(!expand)}>
            <MoreHoriz />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow rows={4}>
        <TableCell colSpan={4} sx={{ padding: '0' }}>
          <Collapse in={expand}>
            <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'flex-start', padding: '1rem 0' }}>
              <InformactionWindow label='Event' width='calc(50% - .5rem)'>
                {log.event}
              </InformactionWindow>
              <InformactionWindow label='Pozycja ' width='calc(50% - .5rem)'>
                {log.player.position.x + ', ' + log.player.position.y + ', ' + log.player.position.z}
              </InformactionWindow>
              <InformactionWindow label='Data' width='100%'>
                <pre>
                  {JSON.stringify(log.data, undefined, 2)}
                </pre>
              </InformactionWindow>
              <InformactionWindow label='Gracz' width='100%'>
                Name: {log.player.name || 'Nieznany'}<br/>
                Steamid64: {log.player.steamid || 'Nieznany'}
              </InformactionWindow>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}