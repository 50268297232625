import axios from 'axios'

const instance = axios.create({
    withCredentials: true,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
    },
    baseURL: 'https://www.humanityrp.pl:9090'
})

export default instance