import { useEffect, useState } from "react"
import axios from '../../app/axios'
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow}
from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import {
  applicationsIsLoading,
  getApplications,
  setApplications,
  setApplicationsIsLoading
} from "../../app/slice/aplicationsSlice"
import { ApplicationsPage } from "./ApplicationsStyled"
import { Link } from "react-router-dom"
import { Box } from "@mui/system"
import { Refresh } from "@mui/icons-material"

export default function Applications() {
  const dispatch = useDispatch()
  const isLoading = useSelector(applicationsIsLoading)
  const applications = useSelector(getApplications)
  const [applicationsFilter, setApplicationsFilter] = useState('new')

  const fetchApplications = () => {
    axios.get('/application/getapplications')
      .then(res => {
        switch (res.data.code) {
          case 'APPLICATIONS_GET_SUCCESS':
            dispatch(setApplications(res.data.applications))
            dispatch(setApplicationsIsLoading(false))
            break;
          case 'DB_ERROR':
            dispatch(setApplicationsIsLoading(true))
            break;
          default:
            break;
        }
      })
      .catch(err => {
        console.error(err)
      })
  }
  useEffect(() => {
    fetchApplications()
  }, [])

  return (
    <ApplicationsPage>
      {
        isLoading ? <CircularProgress />
          : (
            <TableContainer sx={{ maxWidth: '1000px' }} component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                          Aplikacje: {applicationsFilter === 'new' ? 'Oczekujące' : 'Rozpatrzone'}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box>
                            <IconButton onClick={() => fetchApplications()} >
                              <Refresh />
                            </IconButton>
                          </Box>
                          <Box>
                            <Button onClick={() => setApplicationsFilter(applicationsFilter === 'new' ? 'old' : 'new')}>
                              Zmień filtr
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center'>Discord</TableCell>
                    <TableCell align='center'>Steamid64</TableCell>
                    <TableCell align='center'>Status</TableCell>
                    <TableCell align='center'>Akcja</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {
                      applications[applicationsFilter === 'new' ? 'new' : 'old'].map(application => (
                        <TableRow key={application.id}>
                          <TableCell align='center' sx={{ maxWidth: '1rem', overflowX: 'auto' }}>{application.discord}</TableCell>
                          <TableCell align='center' sx={{ maxWidth: '1rem', overflowX: 'auto' }}>{application.steamid64}</TableCell>
                          <TableCell align='center' sx={{ maxWidth: '1rem', overflowX: 'auto' }}>{application.status.status}</TableCell>
                          <TableCell align='center' width='150px'>
                            <Button component={Link}
                              to={`/dashboard/application/${application.steamid64}`}>
                              Zobacz
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                </TableBody>
              </Table>
            </TableContainer>
          )
      }
    </ApplicationsPage>
  )
}