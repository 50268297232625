import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

const initialState = {
  notifications: []
}

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notifications = [
        ...state.notifications,
        {
          id: uuidv4(),
          type: action.payload.type,
          label: action.payload.label,
          msg: action.payload.msg
        }
      ]
    },

    deleteNotification: (state, action) => {
      const notification = state.notifications.findIndex(el => el.id === action.payload.id)

      state.notifications.splice(notification, 1)
    },

    clearNotifications: (state, action) => {
      state.notifications = []
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  addNotification,
  deleteNotification,
  clearNotifications
} = notificationSlice.actions

export const getNotifications = state => state.notifications.notifications

export default notificationSlice.reducer