import { Turn as Hamburger } from 'hamburger-react'

function HamburgerMenu({ open, toggleSidebar }) {

    return (
        <Hamburger
            size={20}
            direction='right'
            color='#EEEEEE'
            toggled={open}
            toggle={() => toggleSidebar()}
            onToggle={() => toggleSidebar()}
        />
    )
}

export default HamburgerMenu