import './Sidebar.css'
import NavButton from '../Buttons/NavButton'
import {
    useEffect,
    useState
} from 'react'

function Sidebar({ open, toggleSidebar }) {
    const [width, setWidth] = useState(null)

    const hideSidebar = () => {
        setWidth(window.innerWidth)
        width >= 762 && toggleSidebar()
    }

    useEffect(() => {
        window.addEventListener('resize', hideSidebar)
        return () => {
            window.addEventListener('resize', hideSidebar)
        }
    })

    return (
        <div className={`sidebar ${open && 'sidebar-open'}`}>
            <div className='buttons-area'>
                <NavButton to='/application'>
                    Aplikacja whitelist
                </NavButton>
                <NavButton
                    to='#discord'
                    onClick={() => window.open('https://discord.com/invite/humanityrp', "_blank")}>
                    Discord
                </NavButton>
                <NavButton to='/rules'>
                    Regulamin
                </NavButton>
                <NavButton to='/lore'>
                    Lore
                </NavButton>
                <NavButton to='/faq'>
                    Najczęstsze pytania
                </NavButton>
                <NavButton to='/donate'>
                    Wsparcie
                </NavButton>
            </div>
        </div>
    )
}

export default Sidebar