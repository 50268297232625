import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import axios from '../../app/axios'
import { useDispatch, useSelector } from "react-redux"
import { getApplication, setApplication, setApplicationIsLoading } from "../../app/slice/applicationATSlice"
import { useNavigate, useParams } from "react-router-dom"
import { ApplicationUserInfo, ApplicationsPage } from "./ApplicationsStyled"
import { Box, Button, CircularProgress, Divider, List, TextField, Typography } from "@mui/material"
import InformactionWindow from "../../components/InformactionWindow/InformactionWindow"
import DialogCustom from "../../components/Dialog/Dialog"

export default function Application() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const applicationData = useSelector(getApplication)
  const [message, setMessage] = useState('')
  const [openAccept, setOpenAccept] = useState(false)
  const [openDeny, setOpenDeny] = useState(false)
  const [openBlock, setOpenBlock] = useState(false)

  useEffect(() => {
    dispatch(setApplicationIsLoading(true))
    axios.get(`/application/getat/${params.steamid}`)
      .then(res => {
        const data = res.data
        switch (data.code) {
          case 'APPLICATION_ACCESS_DENY':
            navigate(0)
            break;
          case 'DB_ERROR':
            enqueueSnackbar(res.data.msg, { variant: 'error' })
            break;
          case 'APPLICATION_CANNOT_FIND':
            enqueueSnackbar(res.data.msg, { variant: 'error' })
            break;
          case 'APPLICATION_GET_SUCCESS':
            dispatch(setApplication(data.application))
            dispatch(setApplicationIsLoading(false))
            break;
          default:
            break;
        }
      })
      .catch(err => console.error(err))
  }, [])

  const acceptApplication = () => {
    axios.post(`/application/accept/${params.steamid}`, {
      cftoolsId: applicationData.application.cftools_id,
      discordId: applicationData.application.discord_id,
      discord: applicationData.application.discord,
      message: message
    })
      .then(res => {
        const data = res.data
        switch (data.code) {
          case 'APPLICATION_ACCEPT':
            navigate(0)
            enqueueSnackbar(res.data.msg, { variant: 'success' })
            break;
          case 'DB_ERROR':
            enqueueSnackbar(res.data.msg, { variant: 'error' })
            break;
          case 'APPLICATION_CANNOT_FIND':
            enqueueSnackbar(res.data.msg, { variant: 'error' })
            break;
          default:
            break;
        }
      })
      .catch(err => console.error(err))
  }

  const denyApplication = () => {
    axios.post(`/application/deny/${params.steamid}`, {
      cftoolsId: applicationData.application.cftools_id,
      discordId: applicationData.application.discord_id,
      discord: applicationData.application.discord,
      message: message
    })
      .then(res => {
        const data = res.data
        switch (data.code) {
          case 'APPLICATION_DENY':
            navigate(0)
            setOpenDeny(false)
            setMessage('')
            enqueueSnackbar(res.data.msg, { variant: 'success' })
            break;
          case 'DB_ERROR':
            enqueueSnackbar(res.data.msg, { variant: 'error' })
            break;
          case 'APPLICATION_CANNOT_FIND':
            enqueueSnackbar(res.data.msg, { variant: 'error' })
            break;
          default:
            break;
        }
      })
      .catch(err => console.error(err))
  }

  const blockUser = () => {
    axios.post(`/user/block/${params.steamid}`, {
      cftoolsId: applicationData.application.cftools_id,
      discord: applicationData.application.discord,
      message: message
    })
      .then(res => {
        const data = res.data
        switch (data.code) {
          case 'USER_DB_BLOCK':
            navigate(0)
            setOpenBlock(false)
            enqueueSnackbar(res.data.msg, { variant: 'success' })
            break;
          case 'DB_ERROR':
            enqueueSnackbar(res.data.msg, { variant: 'error' })
            break;
          default:
            break;
        }
      })
      .catch(err => console.error(err))
  }

  const unblockUser = () => {
    axios.post(`/user/unblock/${params.steamid}`, {
      cftoolsId: applicationData.application.cftools_id,
      message: message
    })
      .then(res => {
        const data = res.data
        switch (data.code) {
          case 'USER_DB_UNBLOCK':
            navigate(0)
            setOpenBlock(false)
            enqueueSnackbar(res.data.msg, { variant: 'success' })
            break;
          case 'DB_ERROR':
            enqueueSnackbar(res.data.msg, { variant: 'error' })
            break;
          default:
            break;
        }
      })
      .catch(err => console.error(err))
  }

  return (
    <ApplicationsPage>
      {
        applicationData.isLoading ? <CircularProgress />
          : (
            <Box sx={{ maxWidth: '1000px', display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'flex-start', flexWrap: 'wrap', width: '100%' }}>
                <Button variant='contained'
                  target='_blank'
                  href={`https://steamcommunity.com/profiles/${applicationData.application.steamid64}`}>
                  Profil Steam
                </Button>
                <Button variant='contained'
                  target='_blank'
                  href={`https://app.cftools.cloud/profile/${applicationData.application.cftools_id}`}>
                  Profil CF
                </Button>
                {
                  applicationData.application.status.status === 'Rozpatrywana' && (
                    <>
                      <Button variant='contained' color='success' onClick={() => setOpenAccept(true)}>
                        Akceptuj
                      </Button>
                      <Button variant='contained' color='error' onClick={() => setOpenDeny(true)}>
                        Odrzuć
                      </Button>
                    </>
                  )
                }
                {
                  applicationData.application.status.status === 'Zaakceptowana' && (
                    <Button variant='contained' color='error' onClick={() => setOpenDeny(true)}>
                      Odrzuć
                    </Button>
                  )
                }
                {
                  applicationData.application.status.status === 'Odrzucona' && (
                    <Button variant='contained' color='success' onClick={() => setOpenAccept(true)}>
                      Akceptuj
                    </Button>
                  )
                }
                {
                  applicationData.application.isBlocked ? (
                    <Button color='error' variant='contained' onClick={() => unblockUser(true)}>
                      Odblokuj
                    </Button>
                  ) : (
                    <Button color='error' variant='contained' onClick={() => setOpenBlock(true)}>
                      Zablokuj
                    </Button>
                  )
                }
              </Box>
              <ApplicationUserInfo>
                <InformactionWindow label='Discord' sx={{ flexBasis: '33.33%' }} width='calc(33% - .5rem)'>
                  {applicationData.application.discord}
                </InformactionWindow>
                <InformactionWindow label='Steamid64' sx={{ flexBasis: '33.33%' }} width='calc(33% - .71rem)'>
                  {applicationData.application.steamid64}
                </InformactionWindow>
                <InformactionWindow label='Status' sx={{ flexBasis: '33.33%' }} width='calc(33% - .5rem)'>
                  {applicationData.application.status.status}
                </InformactionWindow>
                <InformactionWindow label='Wiadomość od sprawdzającego' width='100%'>
                  {applicationData.application.status.message || 'Brak'}
                </InformactionWindow>
                <InformactionWindow label='Aplikowano' width='calc(50% - .5rem)'>
                  {applicationData.application.created_at}
                </InformactionWindow>
                <InformactionWindow label='Edytowano' width='calc(50% - .5rem)'>
                  {applicationData.application.updated_at}
                </InformactionWindow>
                <List sx={{ width: '100%' }}>
                  <Divider />
                </List>
                <InformactionWindow label='Imię i nazwisko' width='100%'>
                  {applicationData.application.character_name}
                </InformactionWindow>
                <InformactionWindow label='Historia postaci' width='100%' contentMaxHeight='250px'>
                  {applicationData.application.character_history}
                </InformactionWindow>
                <InformactionWindow label='Doświadczenie z RP' width='100%' contentMaxHeight='250px'>
                  {applicationData.application.expirance_rp || ''}
                </InformactionWindow>
                {
                  Object.keys(applicationData.application.questions).map(question => (
                    <InformactionWindow
                      label={applicationData.application.questions[question].quest}
                      width='100%'
                      labelLineHeight='normal'
                      contentMaxHeight='250px'>
                      {applicationData.application.questions[question].anwser}
                    </InformactionWindow>
                  ))
                }
              </ApplicationUserInfo>
              <DialogCustom open={openAccept} closeAction={() => setOpenAccept(false)} label='Odrzuć'
                buttons={
                  <>
                    <Button variant='contained' onClick={() => acceptApplication()}>
                      Zaakceptuj
                    </Button>
                    <Button variant='contained' onClick={() => setOpenAccept(false)}>
                      Anuluj
                    </Button>
                  </>
                }>
                <Typography component='span'>
                  Czy chcesz przyjąć aplikację {applicationData.application.discord}?
                  <TextField label='Wiadomość (opcjonalne)' variant='filled'
                    size='small'
                    value={message || ''}
                    onChange={e => setMessage(e.target.value)}
                    sx={{ width: '100%', marginTop: '1rem' }} />
                </Typography>
              </DialogCustom>
              <DialogCustom open={openDeny} closeAction={() => setOpenDeny(false)} label='Odrzuć'
                buttons={
                  <>
                    <Button variant='contained' onClick={() => denyApplication()}>
                      Odrzuć
                    </Button>
                    <Button variant='contained' onClick={() => setOpenDeny(false)}>
                      Anuluj
                    </Button>
                  </>
                }>
                <Typography component='span'>
                  Czy chcesz odrzucić aplikację {applicationData.application.discord}?
                  <TextField label='Wiadomość (opcjonalne)' variant='filled'
                    size='small'
                    value={message || ''}
                    onChange={e => setMessage(e.target.value)}
                    sx={{ width: '100%', marginTop: '1rem' }} />
                </Typography>
              </DialogCustom>
              <DialogCustom open={openBlock} closeAction={() => setOpenBlock(false)} label='Odrzuć'
                buttons={
                  <>
                    <Button variant='contained' onClick={() => blockUser()}>
                      Zablokuj
                    </Button>
                    <Button variant='contained' onClick={() => setOpenBlock(false)}>
                      Anuluj
                    </Button>
                  </>
                }>
                <Typography component='span'>
                  Czy zablokować urzytkownika {applicationData.application.discord}?
                </Typography>
              </DialogCustom>
            </Box>
          )
      }
    </ApplicationsPage>
  )
}
