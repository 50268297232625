import { ExpandLess, ExpandMore } from '@mui/icons-material'
import Banner from '../../components/Banner/Banner'
import './faq.scss'
import { useState } from 'react'
import { Collapse } from '@mui/material'
import Howjoin from './Howjoin/Howjoin'
import { useNavigate } from 'react-router-dom'
import AdSense from 'react-adsense'
import syberiaIcons from '../../images/syberiaicons.png'
import delLogsImg from '../../images/dellogs.jpg'

export default function Fag() {
  const navigate = useNavigate()
  const [openHowjoin, setOpenHowjoin] = useState(false)
  const [openMedicine, setOpenMedicine] = useState(false)

  return (
    <div className='faq-page'>
      <Banner label='Najczęstrze pytania' />
      <section>
        <div className='element'>
          <h1 onClick={() => setOpenHowjoin(!openHowjoin)}>
            📌 Jak dołączyć? {openHowjoin ? <ExpandLess /> : <ExpandMore />}
          </h1>
          <Collapse className='collapse' in={openHowjoin}>
            <Howjoin />
          </Collapse>
        </div>
        <div className='element'>
          <h1>
            📌 Jak przetrwać pierwsze minuty?
          </h1>
          <span className='collapse'>
            Początki bywają trudne, aby poradzić sobie w tym niepzyjemnym świecie, pierwsze co warto stworzyć to broń, może być to naostrzony patyk, kamienny nóż lub wócznia.
            Najlepszym sposobem aby poradzić sobie z głodem i stanem nawodnienia postaci są owoce, znajdziesz je pod odpowiednimi drzewami.
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 Jak działa system progresji postaci?
          </h1>
          <span className='collapse'>
            Podczas tworzenia nowej postaci możesz wybrać początkowy zestaw umiejętności.
            Twoja postać posiada 17 umiejętności, które możesz ulepszać.
            Każda umiejętność ma 100 poziomów, każdy z nich daje wybór kilku unikalnych profitów.
            Możesz wybrać tylko jeden atut dla każdego poziomu umiejętności, co pozwoli ci stworzyć unikalną konfigurację dla twojej postaci.
            Umiejętności są przypinane do profilu postaci i NIE RESETUJĄ SIĘ po śmierci, lecz tracisz część doświadczenia. Dlatego proces ulepszania jest złożony i długotrwały.<br/>
            Aby otworzyć menu umiejętności w grze - naciśnij przycisk „O” (w razie potrzeby można zmienić w ustawieniach, zakładka "HUMANITYRP")
          </span>
        </div>
        <div className='element'>
          <h1 onClick={() => setOpenMedicine(!openMedicine)}>
            📌 Jak działa medycyna? {openMedicine ? <ExpandLess /> : <ExpandMore />}
          </h1>
          <Collapse className='collapse' in={openMedicine}>
            Nowy system medycyny całkowicie zastępuje standardowy system!<br/>
            Ponad 10 nowych chorób i urazów.<br/>
            Ponad 100 prawdziwych leków i narkotyków.<br/>
            Zaawansowany system urazów i operacji.<br/>

            <h1 style={{ background: 'transparent', cursor: 'default', padding: 0, marginTop: '1rem' }}>
              Medykamenty
            </h1>
            Medykamenty dzielą się na 3 kategorie: tabletki, ampułki i zastrzyki.

            <h2>Tabletki</h2>
            Tabletki zwykle leczą początkowe stadia choroby i są stosowane w celu zapobiegania chorobom.
            Opakowanie zawiera do 10 tabletek. Pakiety z tymi samymi tabletami można łączyć.

            <h2>Ampułki</h2>
            Ampułki zawierają silne leki do leczenia złożonych chorób lub chorób w średnim i zaawansowanym stadium.
            Każda ampułka zawiera substancje na 3 wstrzyknięcia.
            Do wstrzyknięcia substancji należy użyć strzykawki.
            Po każdym wstrzyknięciu strzykawka brudzi się i musi być dezynfekowana alkoholem lub gotowana w garnku z wodą.

            <h2>Wtryskiwacze</h2>
            Wtryskiwacze są analogiczne do ampułek, ale do ich użycia nie trzeba używać strzykawek.
            Wszystkie wtryskiwacze są jednorazowego użytku.

            <h1 style={{ background: 'transparent', cursor: 'default', padding: 0, marginTop: '1rem' }}>
              Choroby, stany, efekty
            </h1>
            <img src={syberiaIcons} alt='syberiaIcons' style={{ width: '100%' }} />
          </Collapse>
        </div>
        <div className='element'>
          <h1>
            📌 Gdzie znajdę LORE serwera aby móc wczuć się w imersję i napisać odpowiednią historie postaci?
          </h1>
          <span className='collapse'>
            Kliknij aby przeczytać <a href='#lore' onClick={() => navigate('/lore')}>lore</a>
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 Chciałbym skontaktować się z członkiem administracji. Jak to zrobić?
          </h1>
          <span className='collapse'>
            Najlepszym i najszybszym kontaktem z administracją jest utworzenie TICKETU na kanale 《🔨》otwórz-ticket
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 Mam fajny pomysł dotyczący rozwoju serwera, chciałbym go przedstawić społeczności. Gdzie mogę to zrobić?
          </h1>
          <span className='collapse'>
          Propozycje swoje można składać, proponować na kanale《📂》propozycje
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 Kiedy są restarty serwera?
          </h1>
          <span className='collapse'>
            Restarty serwera odbywają się w godzinach: 00:00, 04:00, 08:00, 12:00, 16:00, 20:00
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 Jak używać komend narracyjnych?
          </h1>
          <span className='collapse'>
            !me czynność lub !do opis
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 Jak korzystać z  《📡》radio
          </h1>
          <span className='collapse'>
            Skontaktuj się za pomocą 《🔨》otwórz-ticket z administracją i wyślij treść oraz częstotliwość w TICKET.
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 "Połączenie zablokowane, odezwij się na discordzie"
          </h1>
          <span className='collapse'>
            Napisz do nas 《🔨》otwórz-ticket i wyślij steamID64.
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 "Ile czasu sprawdzana jest aplikacja WL?"
          </h1>
          <span className='collapse'>
            Wszystko zależy od ilości aplikacji. Czasem zajmuje to kilka godzin, a czasem kilka dni. Cierpliwości.
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 "Czy mogę kupić lepszy bilet?"
          </h1>
          <span className='collapse'>
            Możliwość zakupu kolejki priorytetowej pojawia się na początku każdego miesiąca. <a href='#donator' onClick={() => navigate('/donate')}>Wsparcie</a>
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 "Client not responding" podczas ładowania
          </h1>
          <span className='collapse'>
            Włącz launcher, kliknij "graj", a następnie dołącz na server z poziomu menu DayZ
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 "Nie mogę otworzyć ekwipunku, nie mogę podjąć żadnej interakcji"
          </h1>
          <span className='collapse'>
            Spróbuj podskoczyć, a jeśli to nie pomoże to zrób reloga.
          </span>
        </div>
        <div className='element'>
          <h1>
            📌 "Co mam zrobić jak nie wyszukuje serwera?"
          </h1>
          <span className='collapse'>
            Pobierz DZSA Launcher, tam na powinno wyszukać Ci nasz serwer.
          </span>
        </div>
      </section>
      <AdSense.Google
        client='9296087987995778'
        slot='2571663002'
        style={{ width: '100%', height: 300, float: 'left' }}
        format=''
      />
    </div>
  )
}