import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import Banner from '../../components/Banner/Banner'
import './donate.scss'
import survival from '../../images/survival.jpg'

export default function Donate() {
  return (
    <div className='donate-page'>
      <Banner label='Wsparcie' />
      <div className='content'>
        <span>
          Formą płatności jest PayPal: <a target="_blank" href="https://www.paypal.com/paypalme/mahauu">PayPal</a><br/>
          Jeśli chcesz skozystać z innej formy płatności skontaktuj się z Mahauu poprzez prywatną wiadomość Discord.<br/>
          W tytule wpłaty wpisz swój nick z Discord wraz z tagiem.<br/>
          Wszelkie dane dotyczące darowizn będą dostępne tylko i wyłącznie dla właścicieli serwera HumanityRP.
        </span>
        <span>
          Jeśli masz jakiekolwiek pytania, skontaktuj się z administratorami serwera HumanityRP poprzez ticket na naszym serwerze <a target="_blank" href="https://discord.com/invite/humanityrp">Discord</a>.
        </span>
        <span>
          Więcej informacji na temat zasad monetyzacji znajdziesz pod tym linkiem: <a target="_blank" href="https://www.bohemia.net/monetization">Monetyzacja Bohemia Interactive</a>
        </span>
        <Card>
          <CardActionArea>
            <CardMedia component='img' height='140' src={survival} alt='Dobrowolne wsparcie' />
          </CardActionArea>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Typography variant='h4' sx={{ marginTop: 0 }}>
              Dobrowolne wsparcie
            </Typography>
            <span>
              Poprzez dobrowolne wsparcie otrzymasz rangę Donator na naszym serwerze Discord.<br/>
              Osoby z rangą Donator otrzymają dostęp do specjalnej kategorii z kanałami przeznaczonymi dla donatorów na naszym serwerze Discord.
            </span>
            <span>
              Minimum 20zł (PLN) miesięcznie lub więcej.
            </span>
          </CardContent>
        </Card>
        <Card>
          <CardActionArea>
            <CardMedia component='img' height='140' src={survival} alt='Dobrowolne wsparcie' />
          </CardActionArea>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Typography variant='h4' sx={{ marginTop: 0 }}>
              Kolejka piorytetowa
            </Typography>
            <span>
              Nadanie kolejki priorytetowej oznacza, że wchodząc na serwer pominiesz kolejkę i
              zajmiesz pierwsze dostępne miejsce przed osobami bez kolejki priorytetowej.<br/>
              Miejsca są limitowane, obserwój kanał #kolejka na naszym serwerze Discord.<br/>
              <b>Pula jest ograniczona, możliwość zakupu w pierwsze dni każdego miesiąca. Oczekuj na informacje na kanale #《📢》ogłoszenia.</b>
            </span>
            <span>
              50zł (PLN) miesięcznie za miejsce w kolejce priorytetowej.
            </span>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}