import './Banner.css'

function Banner({ label, className }) {
    return (
        <div className={`banner ${className}`}>
            <span className='banner-label'>
                {label}
            </span>
        </div>
    )
}

export default Banner