import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import { Header } from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Home } from '../../views/Home/Home'
import { Rules } from '../../views/Rules/Rules'
import { Lore } from '../../views/Lore/Lore'
import ApplicationStart from '../../views/Application/AplicationStart'
import Application from '../../views/Application/Aplication'
import ApplicationCreator from '../../views/Application/AplicationCreator'
import AuthForWhitelist from '../../hoc/AuthForWhitelist'
import Donate from '../../views/Donate/Donate'
import AuthError from '../AuthError/AuthError'
import Blocked from '../Blocked/Blocked'
import Fag from '../Faq/Fag'

export default function MainPage() {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/rules" element={<Rules />} />
        <Route exact path="/lore" element={<Lore />} />
        <Route exact path="/faq" element={<Fag />} />
        <Route exact path="/donate" element={<Donate />} />
        <Route exact path="/application" element={<ApplicationStart />} />
        <Route exact path="/autherror" element={<AuthError />} />
        <Route exact path="/blocked" element={<Blocked />} />
        <Route exact path="/application/new" element={
          <AuthForWhitelist>
            <ApplicationCreator />
          </AuthForWhitelist>
        } />
        <Route exact path="/application/:steamid" element={
          <AuthForWhitelist>
            <Application />
          </AuthForWhitelist>
        } />
      </Routes>
      <div className='footer-wrap' />
      <Footer />
    </>
  )
}