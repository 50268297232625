import { useState, useEffect } from 'react'
import { Button, TextField } from '@mui/material'
import Banner from '../../components/Banner/Banner'
import { useNavigate } from 'react-router-dom'
import './aplication.scss'
import { useDispatch, useSelector } from 'react-redux'
import UserBar from '../../components/UserBar/UserBar'
import { clearApplication, getApplication, setApplication } from '../../app/slice/aplicationSlice'
import axios from '../../axios'
import { useSnackbar } from 'notistack'
import { getUser } from '../../app/slice/userSlice'

export default function ApplicationCreator() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const application = useSelector(getApplication)
  const [historyCharacterCount, setHistoryCharacterCount] = useState(0)
  const [errors, setErrors] = useState({
    steamid64: "",
    discord: "",
    characterName: "",
    characterHistory: "",
    expiranceRP: "",
    question1: "",
    question2: "",
    question3: "",
    question4: "",
  })
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const sendApplication = () => {
    axios.post('/application/send', {
      characterName: application.characterName,
      characterHistory: application.characterHistory,
      expiranceRP: application.expiranceRP,
      question1: application.question1,
      question2: application.question2,
      question3: application.question3,
      question4: application.question4,
    })
    .then(res => {
      switch (res.data.code) {
        case 'COMPILE_SUCCESS':
          enqueueSnackbar(res.data.msg, { variant: 'success' })
          dispatch(clearApplication())
          setErrors({
            steamid64: "",
            discord: "",
            characterName: "",
            characterHistory: "",
            expiranceRP: "",
            question1: "",
            question2: "",
            question3: "",
            question4: "",
          })
          navigate(`/application/${user.steamid64}`)
          break;
        case 'COMPILE_ERROR':
          enqueueSnackbar(res.data.msg, { variant: 'error' })
          setErrors(res.data.errors)
          break;
        case 'DB_ERROR':
          enqueueSnackbar(res.data.msg, { variant: 'error' })
          break;
        case 'AUTH_ERROR':
          enqueueSnackbar(res.data.msg, { variant: 'error' })
          break;
        default:
          break;
      }
    })
    .catch(err => console.error(err))
  }

  useEffect(() => {
    setHistoryCharacterCount(application.characterHistory.replace(/\s/g, '').length)
  }, [application.characterHistory])

  return (
    <div className='application-page'>
      <Banner label='Nowa aplikacja' />
      <div className='application-container'>
        <UserBar />
        <h1>Aplikacja na whitelist</h1>

        <div className='info'>
          <h2>
            Podstawowe kryteria
          </h2>
          <span>
            1. Musisz na naszym serwerze <a href='https://discord.com/invite/humanityrp' target='_blank'>Discord</a>,
          </span>
          <span>
            2. Musisz zapoznać się z <a href='#regulamin' onClick={() => navigate('/rules')}>regulaminem</a> oraz <a href='#lore' onClick={() => navigate('/lore')}>lore</a> serwera,
          </span>
          <span>
            3. Zabronione Celebrity Name,
          </span>
          <span>
            4. Historia postaci przynajmniej 2000 znaków*,
          </span>
          <span>
            5. Kreatywność, odrobina wyobraźni oraz odpowiedzi pełnym zdaniem.
          </span>
          <span>
            * Próba ominięcia minimalnej liczby znaków skutkuje blokadą aplikowania na serwer.
          </span>
        </div>
        <TextField label="Imię i nazwisko" variant="filled"
          className='TextField-root FilledInput-underline'
          error={errors.characterName ? true : false}
          helperText={errors.characterName}
          onChange={e => dispatch(setApplication({ element: 'characterName', text: e.target.value }))}
          sx={{ width: '100%' }} />
        <TextField label={`Historia postaci (${historyCharacterCount}/2000)`} variant="filled"
          className='TextField-root FilledInput-underline'
          error={errors.characterHistory ? true : false}
          helperText={errors.characterHistory}
          onChange={e => dispatch(setApplication({ element: 'characterHistory', text: e.target.value }))}
          sx={{ width: '100%' }}
          multiline
          maxRows={10} />
        <TextField label="Twoje doświadczenie z RP" variant="filled"
          className='TextField-root FilledInput-underline'
          error={errors.expiranceRP ? true : false}
          helperText={errors.expiranceRP}
          onChange={e => dispatch(setApplication({ element: 'expiranceRP', text: e.target.value }))}
          sx={{ width: '100%' }}
          multiline
          maxRows={4} />
        <div className='question'>
          Odłączyłeś/aś się od grupy, Twoja postać ZGINĘŁA. Odradzasz się, wracasz do swojego obozowiska/swoich znajomych. Zaczynają się Ciebie pytać co się stało. Jak reagujesz, co odpowiadasz? Opisz krótko sytuację oraz uzasadnij swoją odpowiedź.
        </div>
        <TextField label="Odpowiedź #1" variant="filled"
          className='TextField-root FilledInput-underline'
          error={errors.question1 ? true : false}
          helperText={errors.question1}
          onChange={e => dispatch(setApplication({ element: 'question1', text: e.target.value }))}
          sx={{ width: '100%' }}
          multiline
          maxRows={4} />
        <div className='question'>
          Napada na Ciebie uzbrojona grupa, celują do Ciebie, z sytuacji wynika, że chcą byś oddał/a im swoje rzeczy. Opisz co robisz w tej sytuacji.
        </div>
        <TextField label="Odpowiedź #2" variant="filled"
          className='TextField-root FilledInput-underline'
          error={errors.question2 ? true : false}
          helperText={errors.question2}
          onChange={e => dispatch(setApplication({ element: 'question2', text: e.target.value }))}
          sx={{ width: '100%' }}
          multiline
          maxRows={4} />
        <div className='question'>
          Napadasz na gracza. Opisz jak odegrałbyś taką akcję. W swojej wypowiedzi uwzględnij z jakich przedmiotów możesz ograbić innych, oraz uzasadnij swoją wypowiedź.
        </div>
        <TextField label="Odpowiedź #3" variant="filled"
          className='TextField-root FilledInput-underline'
          error={errors.question3 ? true : false}
          helperText={errors.question3}
          onChange={e => dispatch(setApplication({ element: 'question3', text: e.target.value }))}
          sx={{ width: '100%' }}
          multiline
          maxRows={4} />
        <div className='question'>
          Wędrowałeś/aś ze swoim kompanem, rozdzieliła Was horda zombie, rozbiegliście się w dwóch różnych kierunkach i zgubiłeś drogę do obozowiska. Jak poinformujesz kolegę gdzie się znajdujesz? Jak znajdziesz drogę do domu? Opisz krótko sytuację.
        </div>
        <TextField label="Odpowiedź #4" variant="filled"
          className='TextField-root FilledInput-underline'
          error={errors.question4 ? true : false}
          helperText={errors.question4}
          onChange={e => dispatch(setApplication({ element: 'question4', text: e.target.value }))}
          sx={{ width: '100%' }}
          multiline
          maxRows={4} />
        <Button variant='contained'
          className='Button-contained'
          size='large'
          onClick={() => sendApplication()}>
          Aplikuj
        </Button>
      </div>
    </div>
  )
}