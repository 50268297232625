import React from "react"
import { NavLink as NavLinkBase } from 'react-router-dom'
import styled from "styled-components"

const NavLinkStyled = styled(NavLinkBase)`
  text-decoration: none;
  color: #EEEEEE;
`

const NavLink = React.forwardRef((props, ref) => (
  <NavLinkStyled
    ref={ref}
    {...props}
    className={props.activeClassName}
  />
))

export default NavLink