import { useEffect, useState } from 'react'
import { Navigate } from "react-router-dom"
import axios from '../app/axios'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, isAuth, isAuthUser, isLoadingUser, setAuth, setLoading, setUser } from '../app/slice/userSlice'
import { CircularProgress } from '@mui/material'

export default function AuthForAdminTools({ children }) {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const isAuth = useSelector(isAuthUser)
  const isLoading = useSelector(isLoadingUser)

  useEffect(() => {
    dispatch(setLoading(true))
    dispatch(setAuth(false))
    axios.get('/auth/discord/admintools/check')
      .then(res => {
        if (res.data.isAuth) {
          dispatch(setAuth(res.data.isAuth))
          dispatch(setUser(res.data.user))
        }
        dispatch(setLoading(false))
      })
      .catch(err => console.error(err))
  }, [])

  return (
    isLoading
      ? <CircularProgress sx={{ marginTop: '2rem' }} />
      : (isAuth
          ? children
          : <Navigate to='/' />)
    )
}