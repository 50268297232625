import './homeWidget.scss'

export default function HomeWidget({ children, side, label, img }) {
  if (side === 'left') {
    return (
      <div className='home-widget-left'>
        <div className='img'>
          <img src={img} alt='widget-img' />
        </div>
        <div className='info'>
          <div className='label'>
            {label}
          </div>
          <div className='description'>
            {children}
          </div>
        </div>
      </div>
    )
  }

  if (side === 'right') {
    return (
      <div className='home-widget-right'>
        <div className='img'>
          <img src={img} alt='widget-img' />
        </div>
        <div className='info'>
          <div className='label'>
            {label}
          </div>
          <div className='description'>
            {children}
          </div>
        </div>
      </div>
    )
  }
}
