import styled from "styled-components"

export const ApplicationsPage = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 10px !important;
  }
`

export const ApplicationUserInfo = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`