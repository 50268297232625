import { createSlice } from '@reduxjs/toolkit'
import axios from '../../app/axios'

const initialState = {
  isLoading: true,
  logs: [],
  info: []
}

export const moreAdminLogsSlice = createSlice({
  name: 'myAdminLogs',
  initialState,
  reducers: {
    setMoreAdminLogsInfo: (state, action) => {
      state.info = action.payload
      state.isLoading = false
    },
    setMoreAdminLogs: (state, action) => {
      state.logs = [
        ...state.logs,
        ...action.payload
      ]
      state.isLoading = false
    },
    setMoreAdminLogsIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    clearMoreAdminLogs: (state, action) => {
      state.logs = []
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setMoreAdminLogsInfo,
  setMoreAdminLogs,
  setMoreAdminLogsIsLoading,
  clearMoreAdminLogs
} = moreAdminLogsSlice.actions

export const getMoreAdminLogs = state => state.moreAdminLogs

export default moreAdminLogsSlice.reducer