import './Howjoin.scss'
import src1 from '../../../images/jakdolaczyc1.jpg'
import src2 from '../../../images/jakdolaczyc2.png'
import src3 from '../../../images/jakdolaczyc3.png'
import src4 from '../../../images/jakdolaczyc4.png'

export default function Howjoin() {
  return (
    <div className='howjoin-page'>
      <h2>Dane serwera</h2>
      <span>
        Nazwa serwera: [PL] [RP] HumanityRP POLSKI ROLEPLAY | discord.io/HumanityRP<br/>
        IP: 193.25.252.75:2302
      </span>
      <h2>Whitelist</h2>
      <span>
        Aby dołączyć na serwer musisz być wpisany na Whitelist.<br/>
        Aplikację złożysz tutaj - <a href="/application">Aplikację whitelist</a>
      </span>
      <h2>
        Aby zostać wpuszczonym na serwer musisz ustawić imię i nazwisko postaci.
      </h2>
      <span>
        1. Wejdź w menu parametrów oraz zakładkę ulubione<br/>
        2. Zaznacz "ptaszka" przy nazwa profilu i wpisz swoje imię i nazwisko (IC)
      </span>
      <img src={src3} alt="jakdolaczyc3" />
      <h2>
        Aby pobrać mody i dołączyć na serwer wykonaj poniższe punkty.
      </h2>
      <span>
        1. Wchodzisz w listę serwerów<br/>
        2. Zakładka społeczność<br/>
        3, Wpisz w wyszukiwarce "HumanityRP"<br/>
        4. Kliknij dołącz<br/>
        5. Klikasz konfiguruj DLC oraz mody i dołącz<br/><br/>
        Po pobraniu modyfikacji połączysz się z serwerem
      </span>
      <img src={src1} alt="jakdolaczyc1" />
      <img src={src2} alt="jakdolaczyc2" />
      <h2>
        Upewnij się, czy masz wybrany odpowiedni mikrofon jako domyślny do komunikacji w grze.
      </h2>
      <span>
        Wejdź w ustawienia DŹWIEKU w PANELU STEROWNIA i zaznacz te dwie opcje na swoim mikrofonie.
      </span>
      <img src={src4} alt="jakdolaczyc4" />
      <h2>Teraz możesz cieszyć się rozgrywką na naszym serwerze!</h2>
      <span>W razie jakichkolwiek problemów napisz tickert na naszym <a target="_blank" href="https://discord.gg/humanityrp">Discord</a>!</span>
    </div>
  )
}