import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuth: false,
  isLoading: true,
  user: {}
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setAuth: (state, action) => {
      state.isAuth = action.payload
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setUser,
  setAuth,
  setLoading
} = userSlice.actions

export const getUser = state => state.user.user
export const isAuthUser = state => state.user.isAuth
export const isLoadingUser = state => state.user.isLoading

export default userSlice.reducer