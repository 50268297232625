import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: true,
  application: {}
}

export const myApplicationSlice = createSlice({
  name: 'myApplication',
  initialState,
  reducers: {
    setMyApplicationLoading: (state, action) => {
      state.isLoading = action.payload
    },
    loadMyApplication: (state, action) => {
      state.application = action.payload
    },
    setMyApplication: (state, action) => {
      if (action.payload.element === 'question1' || action.payload.element === 'question2' || action.payload.element === 'question3' || action.payload.element === 'question4') {
        console.log(action.payload)
        state.application.questions[action.payload.element].anwser = action.payload.text
      } else {
        state.application[action.payload.element] = action.payload.text
      }

    },
    clearMyApplication: (state, action) => {
      state.application = {}
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  loadMyApplication,
  setMyApplicationLoading,
  setMyApplication,
  clearMyApplication
} = myApplicationSlice.actions

export const myApplicationIsLoading = state => state.myApplication.isLoading
export const getMyApplication = state => state.myApplication.application

export default myApplicationSlice.reducer