import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../../app/axios'
import { useDispatch, useSelector } from 'react-redux'
import { clearMoreAdminLogs, getMoreAdminLogs, setMoreAdminLogs, setMoreAdminLogsIsLoading } from '../../app/slice/moreAdminLogsSlice'
import { Button, CircularProgress, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'
import MoreAdminLog from './MoreAdminLog'
import { MoreAdminLogsPage } from './MoreAdminLogsStyled'
import { Box } from '@mui/system'
import { LoadingButton } from '@mui/lab'

export default function MoreAdminLogs() {
  const dispatch = useDispatch()
  const { folder } = useParams()
  const [count, setCount] = useState(25)
  const [expandAll, setExpandAll] = useState(false)

  const [loadingMore, setLoadingMore] = useState(false)
  const moreAdminLogs = useSelector(getMoreAdminLogs)

  const [event, setEvent] = useState('PLAYER_GEAR')
  const [item, setItem] = useState('')

  useEffect(() => {
    dispatch(setMoreAdminLogsIsLoading(true))
    dispatch(clearMoreAdminLogs())
    axios.get(`/logs/moreadminlogs/get`, {
      params: {
        count: count,
        length: moreAdminLogs.logs.length,
        folder: folder,
        event: event,
        item: item
      }
    })
    .then(res => {
      switch (res.data.code) {
        case 'GET_SUCCESS':
          dispatch(setMoreAdminLogs(res.data.logFile))
          break;
        default:
          break;
      }
    })
    .catch(err =>  console.log(err))
  }, [count, folder, event])

  const applayFilters = () => {
    dispatch(setMoreAdminLogsIsLoading(true))
    dispatch(clearMoreAdminLogs())
    axios.get(`/logs/moreadminlogs/get`, {
      params: {
        count: count,
        length: moreAdminLogs.logs.length,
        folder: folder,
        event: event,
        item: item
      }
    })
    .then(res => {
      switch (res.data.code) {
        case 'GET_SUCCESS':
          dispatch(setMoreAdminLogs(res.data.logFile))
          break;
        default:
          break;
      }
    })
    .catch(err =>  console.log(err))
  }

  const loadMoreLogs = () => {
    setLoadingMore(true)
    axios.get('logs/moreadminlogs/get', {
      params: {
        count: count,
        length: moreAdminLogs.logs.length,
        folder: folder,
        event: event
      }
    })
    .then(res => {
      switch (res.data.code) {
        case 'GET_SUCCESS':
          dispatch(setMoreAdminLogs(res.data.logFile))
          setLoadingMore(false)
          break;
        default:
          break;
      }
    })
    .catch(err =>  console.log(err))
  }

  const events = [
    'PLAYER_GEAR',
    'PLAYER_WEAPON',
    'PLAYER_HANDS',
    'ACTION_LOG',
    'ACTION_CONTINUOUS_LOG',
    'ACTION_CONTINUOUS_SERVER_LOG',
    'ACTION_ANIMATED_LOG',
    'CRAFT_LOG',
    'ITEM_ATTACH',
    'ITEM_DETACH',
    'ITEM_LOCATION',
    'ITEM_PLACED',
  ]

  return (
    <MoreAdminLogsPage>
      <div>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginBottom: '1rem' }}>
          <TextField select variant='filled' value={event} label='Event' sx={{ width: 'calc(50% - .5rem)' }} >
            {
              events.map(event => (
                <MenuItem onClick={() => {
                  setEvent(event)
                  setItem('')
                }} value={event}>{event}</MenuItem>
              ))
            }
          </TextField>
          <TextField variant='filled' label='Gracz'
            placeholder='steamid64'
            sx={{ width: 'calc(50% - .5rem)' }} />
          <TextField variant='filled' label='Pozycja'
            placeholder='x, y, z'
            sx={{ width: 'calc(50% - .5rem)' }} />
          <TextField variant='filled' label='Przedmiot'
            placeholder='Rags'
            sx={{ width: 'calc(50% - .5rem)' }}
            value={item || ''}
            onChange={e => setItem(e.target.value)}/>
          <Button variant='contained'
            sx={{ width: '100%' }}
            onClick={() => applayFilters()}>
            Filtruj
          </Button>
        </Box>
        <TableContainer sx={{ width: '100% !important' }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Data
                </TableCell>
                <TableCell>
                  Pozycja
                </TableCell>
                <TableCell>
                  Gracz
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => setExpandAll(!expandAll)}>
                    <MoreHoriz />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
              moreAdminLogs.isLoading ? <CircularProgress />
              : (
                moreAdminLogs.logs.map((log, i) => (
                  <MoreAdminLog key={i} expandAll={expandAll} log={log} />
                ))
              )
            }
            <TableRow>
              <TableCell colSpan={4}>
                <Button sx={{ width: '100%' }}
                  disabled={loadingMore}
                  onClick={() => loadMoreLogs()}>
                  {
                    loadingMore ? <CircularProgress sx={{ width: '1rem' }} />
                    : 'Pokarz więcej'
                  }
                </Button>
              </TableCell>
            </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </MoreAdminLogsPage>
  )
}