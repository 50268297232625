import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import Login from './views/Login/Login'
import PersistentDrawerLeft from './components/PersistentDrawerLeft/PersistentDrawerLeft';
import AuthForAdminTools from './hoc/AuthForAdminTools'
import MainPage from './views/MainPage/MainPage'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline } from '@mui/material';
import './App.scss'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <ThemeProvider theme={darkTheme}>
        <Router>
          <Routes>
            <Route exact path="/*" element={<MainPage />} />
            <Route exact path="/admintools" element={<Login />} />
            <Route exact path="/dashboard/*" element={
              <AuthForAdminTools>
                <PersistentDrawerLeft />
              </AuthForAdminTools>
            } />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  )
}

export default App
